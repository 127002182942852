import React, { useState } from 'react';
import './Header.css';
import headLogo from "../images/banner.png";
import twitter from "../images/twitter.png";
import chart from "../images/chart.png";
import tg from "../images/tg.png";
import close from "../images/microwave_move1.png"

const Header = () => {
  const [hoveredTwitter, setHoveredTwitter] = useState(false);
  const [hoveredTg, setHoveredTg] = useState(false);
  const [hoveredChart, setHoveredChart] = useState(false);

  return (
    <header className="header">
      <div className="logo">
        <img src={headLogo} alt="head-logo" />
      </div>
      <div className="menu">
        <a href="https://x.com/MicroOnSolana" target='_blank' rel="noopener noreferrer">
          <img
            src={hoveredTwitter ? twitter : close}
            alt="Twitter"
            onMouseOver={() => setHoveredTwitter(true)}
            onMouseLeave={() => setHoveredTwitter(false)}
          />
          <p>twitter</p>
        </a>
        <a href="" target='_blank' rel="noopener noreferrer">
          <img
            src={hoveredTg ? tg : close}
            alt="Telegram"
            onMouseOver={() => setHoveredTg(true)}
            onMouseLeave={() => setHoveredTg(false)}
          />
          <p>telegram</p>
        </a>
        <a href="https://birdeye.so/" target='_blank' rel="noopener noreferrer">
          <img
            src={hoveredChart ? chart : close}
            alt="Chart"
            onMouseOver={() => setHoveredChart(true)}
            onMouseLeave={() => setHoveredChart(false)}
          />
          <p>chart</p>
        </a>
      </div>
    </header>
  );
};

export default Header;
